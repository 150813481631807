.bg-dark {
  /*background-color: rgb( 255, 198, 41) !important*/
    /*background: inherit!important;*/
    background-color: white !important;
}

.navbar, .navbar-brand {
  /*padding-bottom: 25px;
  padding-top: 17px */
  padding-bottom: 0;
  padding-top: 0;
}
.navbar .bar{
  width:100%;
}
.navbar-brand {
  width:100%;
}
.row{
  margin: 0 !important;
}
.row.logos{
  /* width: 30vw; */
  width: 700px;
  max-width: 75vw;
  flex-wrap: nowrap;
}
.row.logos div{
  min-width: 44%;
  margin-right: 8px;
}
.testBanner{
  max-width: 10% !important;
  min-width: 10% !important;
  margin: 0 !important;
}
.testBanner h2{
  width: 100%;
  text-align: center;
}
.navbar-brand img {
  /*width: 124.724409pt; /* nvm, its 44mm now //35mm as per the branding guidelines*/
  /* width: 17.857142857vw; */
  width: 100%;
  height: auto;
}
.navbar .center{
  margin-top: 2vw !important;
  line-height: 4vw;
}

.navbar .lme{
  font-size: 16px;
  display: flex;
  align-items: center;
}

.navbar .lme h1{
  font-size: 1.313em;
  margin: 0 !important;
}

.navbar .lme h2{
  margin: 0 !important;
}

.navbar-toggler{
  margin-bottom:5px;
  margin-top:5px;
}
.navbar .dropdownmenu{
}
@media (min-width: 576px){
  .navbar .center{
    margin-top: 36px;
    margin-bottom:20px;
  }
}
