.video-slider body,
.video-slider html {
  padding: 0 10px;
  margin: 0;
  background: #0e0f11;
  color: #ecf0f1;
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

/*breaking?*/
.video-slider * {
  box-sizing: border-box;
}


.disabledTile{
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  background-color: rgba(55, 58, 54,0.95);
}
.video-slider h1,
.video-slider p {
  text-align: center;
}

.video-slider p {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.video-slider a:link,
.video-slider a:hover,
.video-slider a:active,
.video-slider a:visited {
  transition: color 150ms;
  color: #95a5a6;
  text-decoration: none;
}
.Catalogue {
  width:100%;
  max-width:100vw;
}
.video-slider a:hover {
  color: #7f8c8d;
  text-decoration: underline;
}

.video-slider .contain {
  width: 100%;
}
.video-slider-outer {
  width:100%;
  max-width: 100vw;
}
.video-slider-outer h2{
  margin-left: 1rem
}
.video-slider .row {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  margin:0;
  padding:0;
}

.video-slider .row__inner {
  transition: 450ms transform;
  font-size: 0;
  white-space: nowrap;
  /*padding-bottom: 10px;*/
  height: auto;
}

.video-slider .tile {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 20px;
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
}
.video-slider .tile__media {
  height: auto;
  width: auto;
}
.video-slider .tile__img {
  width: 100%;
  height:auto;
  object-fit: cover;
}
.video-slider .tile__details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 10px;
  opacity: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
  transition: 450ms opacity;
}

.video-slider .tile__details:after,
.video-slider .tile__details:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: #000;
}

/*.video-slider .tile__details:after {
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border: 3px solid #ecf0f1;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}*/

/*.video-slider .tile__details:before {
  content: '▶';
  left: 0; top:0; right:0; bottom: 0;
  width: 100%;
  font-size: 30px;
    margin:auto;
    width:100px;
    height:100px;
    position: absolute;
  z-index: 2;
}*/
.video-slider {
  position:relative;
}
.video-slider .playbtn{
    left:0;
    bottom:30%;
    top:0;
    right:0;
    position:absolute;
    width:50px;
    height:50px;
    margin:auto;
}
.video-slider .playbtn i{
    font-size:50px;
    color:rgba(127, 255, 212,0.7);
}

.video-slider.row {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.video-slider.row::-webkit-scrollbar {
    display: none;
}

.video-slider .tile:hover .tile__details {
  opacity: 1;
}

.desktop .video-slider .row__inner:hover {
  /*transform: translate3d(-62.5px, 0, 0);*/
}

.video-slider .row__inner:hover .tile {
  opacity: 0.3;
}
.video-slider .row__inner .tile:hover {
  opacity: 1;
}
.desktop .video-slider .row__inner:hover .tile:hover {
  /*transform: scale(1.5);*/
  opacity: 1;
}
.desktop .video-slider .tile:hover ~ .tile {
  /*transform: translate3d(50%, 0, 0);*/
}

.desktop .video-slider .currTile:hover ~ .tile {
  /*transform: translate3d(80%, 0, 0);*/
}
.desktop .video-slider .currTile:hover{
  /*transform: translate3d(62.5px, 0, 0) scale(1.5)!important;*/
}

.desktop .video-slider .first:hover {
    /*margin-left: 62.5px*/
}

.video-slider .prev {
    width:10%;
    height:auto;
    position: absolute;
    left:0;
    top:25%;
    background-color: rgba(55, 58, 54, 0.7);
    /*background-color: rgba(66,66,66,0.7);*/
    z-index:1000;
}

.video-slider .next {
    width:10%;
    height:auto;
    position:absolute;
    right:0;
    top:25%;
    bottom:25%;
    background-color: rgba(55, 58, 54, 0.7);
    z-index:1000;
}


@keyframes btnscaleup{
    from{
        transform: scale(1,1);
    }
    to{
        transform: scale(1,1.5);
    }
}
@keyframes btnscaledown{
    from{
        transform: scale(1,1.5);
    }
    to{
        transform: scale(1,1);
    }
}

/*
.desktop .video-slider:hover .next, .desktop .video-slider:hover .prev{
    animation-name: btnscaleup;
    animation-duration: 0.25s;
    transform: scale(1,1.5);
}
.desktop .video-slider .next, .desktop .video-slider .prev{
    animation-name: btnscaledown;
    animation-duration: 0.25s;
    transform: scale(1,1);
    overflow:hidden;

}*/


.video-slider .next i, .video-slider .prev i{
    width:100%;
    height:100%;


}
.video-slider .next i::before, .video-slider .prev i::before {
    font-size: 70px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position:absolute;
    margin: auto;
    width:35px;
    height:75px;
    color:rgba( 255, 255, 255,0.5);

}

.video-slider .tile_bar {
  position:absolute;
  width:15%;
  height:100%;
  right:0;
  top:0;
}

.video-slider .tile_bar .bottom {
  position:absolute;
  padding:20%;
  height:50%;
  top:50%;
  width:100%
}
.video-slider .tile_info {
  position:absolute;
  width:85%;
  height:50%;
  bottom:0;
  left:0;
  padding-left:2%;
}
.video-slider .tile_info h3{
  font-size: 0.75rem;
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  text-overflow: ellipsis;
  overflow:hidden;
  width:100%;
  font-weight:bold;
}
.video-slider .tile_info p{
  font-size: 0.6rem;
  position:relative;
  line-height:1.2;
  text-shadow:0 1px 1px rgba(0,0,0,0.5);
  display:block;
  text-align: left;
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.video-slider .like{
  min-height:1.2rem;
  height:25%;
  width:100%;
}

.video-slider .dislike{
  min-height:1.2rem;
  height:25%;
  width:100%;
}
.video-slider .add{
  margin-top:1.2rem;
  min-height:1.2rem;
  height:25%;
  float:right;
}
.video-slider .add i{
  width:100%;
  font-size: 1.2rem;
  color:white;
}
.video-slider .dislike i{
    font-size: 1.2rem;
    color: #dc3545;
}
.video-slider .like i{
    font-size: 1.2rem;
    color: #28a745;
}
.video-slider .like .badge, .video-slider .dislike .badge{

    margin:1px;
}
.video-slider .likebar{
    height:0px;
    position:absolute;
    bottom:0;
    left:0;
    border-bottom: 3px solid green;
}
.video-slider .dislikebar{
    height:0px;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    border-bottom: 3px solid red;
}
.video-slider .tile__desc{
  color:white;
  font-size: 10px;
  height:auto;
  width:auto;
}
.video-slider .tile__title{
    color:white;
    font-size:10px;
    overflow:hidden;
}

.Catalogue{
  visibility: hidden;
}

@media only screen and (max-width: 720px){

  .video-slider .row__inner:hover .tile:hover {
    transform: none;
    opacity: 1;
  }
  .video-slider .row__inner .tile {
    opacity: 1;
  }

  .video-slider:hover .next, .video-slider:hover .prev{
      animation-name: none;
      animation-duration: 0;
      transform: none;
  }

  .video-slider .row__inner:hover {
    transform: none;
  }

  .video-slider .first:hover {
      margin-left: 0;
  }
}

.subscribe-btn{
    left:10%;
    bottom:60%;
    top:10;
    right:10%;
    position:absolute;
    background-color:#ffc629;
    border-color:#ffc629;
    color: #373a36;
    font-weight: bold;
}

.tile-badge-container{
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tile-badge-container div{
  position: relative;
  width: 100%;
  height: 100%;
  top: calc(-100% - 13px);
  right: -10px;
  pointer-events: none;
}

.tile-badge{
  background-color: #C12900;
  padding: 4px 8px;
  z-index: 10000;
  font-size: 9pt;
  float:right;
}