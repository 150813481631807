input {
  font-family: Helvetica, sans-serif;
}
body {
  background-color: white;
  
  
  background-repeat: no-repeat!important;
  background-attachment: fixed!important;
  background-position: top!important;
  background-size: cover!important;

  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-size: 10pt;
  color: white;
  scrollbar-width: none !important;
}
::-webkit-scrollbar {
  width: 0px;
}
h1 {
  font-family: Helvetica, sans-serif;
  color: #EDEAE3;
  font-size: 30pt;
}

h2 {
  font-family: Helvetica, sans-serif;
  color: white;
  font-size: 14pt;
}
h3 {
  font-family: Helvetica, sans-serif;
  font-size: 14pt;
}
p {
  font-family: Helvetica, sans-serif;
  font-size: 10pt;
  color: white;
}

.jumbotron {
  background: none !important;
}

.jumbotron .inline {
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 10px
}


.footer {
  background: linear-gradient(
      rgb(55, 58, 54),
      rgb(55, 58, 54)
  )
}


html {
  position: relative;
  min-height: 100%;
}

.footer {
  padding-top:20px;
  bottom: 0;
  width: 100%;
  height: auto; 
  min-height: 60px; /* Vertically center the text there */
}

.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.PlaylistImg{
  height:20px;
  width:auto;
  padding: 1px;
}

.isVideo{
  background-color: rgba(255,255,255,0.3);
}

/* Presets */
.yellowText{ /*todo: find all in code and add branding variables*/
  color: #EDEAE3 !important;
}
.blackText{
  color: #373a36 !important;
}
.whiteText{
  color: white !important;
}
.yellow{
  background-color: #EDEAE3 !important;
}
.black{
  background-color: #373a36 !important;
}
.white{
  background-color: white !important;
}
.blackText.hl, .yellowText.hl, .whiteText.hl{
  opacity: 0.9;
  font-size: 12pt;
  /*text-shadow: 0 1px 1px rgba(0,0,0,0.5);*/
}
.headlines {
  font-family: Helvetica, sans-serif
}
.body-sm {
  font-family: Helvetica, sans-serif
}
.body-md {
  font-family: Helvetica, sans-serif
}
.subHeading {
  font-family: Helvetica, sans-serif
}
.text-bd {
  font-size: 10pt;
}
.text-sh {
  font-size: 14pt;
}
.text-hd {
  font-size: 30pt;
}
.heading2{
  font-size: 1.25rem;
  font-weight:bolder;
  font-family: Helvetica, sans-serif
}
.BackButton{
  font-size: 40pt;
}
.BackButton .fa{
  color: white;
}
.BackButton .fa:hover{ /*todo: find and change*/
  color: #EDEAE3;
}

.fill {
  width:100%;
  height:100%;
}
.dropdown-btn-inner{
  width:88%;
  margin:none;
}
.react-datepicker__week, .react-datepicker__month, .react-datepicker-wrapper, .react-datepicker-popper, .react-datepicker, .react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input {
  z-index: 999999 !important;
}
.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input{
  width:100%;
}

.searchTiles:hover {
  background-color: rgba(45, 48, 44, 0.8);
}

.blankScreen{
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile{
  margin-right: 1vw !important;
}

.firstTile{
  margin-right: 0 !important;
  height:10px !important;
}

.pvtCheckContainer p{
  color: #506784
}

a.pvtButton{
  color: #506784 !important;
}

.microsoft-btn{
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-bottom: 8px;
}

.google-btn{
  background: white;
  border: solid 1px #ccc;
  border-radius: 8px;
  padding: 4px 16px;
  color: #757575;
  display: flex;
  justify-content: space-evenly;
  width: 215px;
  cursor: pointer;
}
.auth0-btn{
  background: white;
  border: solid 1px #ccc;
  border-radius: 8px;
  padding: 4px 16px;
  color: #757575;
  display: flex;
  justify-content: space-evenly;
  width: 215px;
  cursor: pointer;
}

.beta{
  text-align: center;
}

.verticalLine{
  min-width: 2px;
  max-width: 2px;
  height: 45%;
  background-color: #9b9dad;
}

.horizontalLine{
  min-height: 2px;
  max-height: 2px;
  width: 45%;
  background-color: #9b9dad;
}

.loginOR{
  border-radius: 10px;
  border: 2px solid #9b9dad;
  text-align: center;
  margin-bottom: 0;
  padding: 16px;
}

.ORRow{
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.ORCol{
  flex-direction: column;
  align-items: center;
}

.flexCol{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toggle-password {
  position: absolute;
  top: calc(50% - 5px);
  right: 20px;
  border: none;
  background: none;
}

.toggle-password-register{
  position: relative;
  top: -25px;
  right: 10px;
  float: right;
  border: none;
  background: none;
}

.backToStandardSignOnBtn{
  background-color: #fff;
  color: #373a36;
  font-size: 0.8rem;
  margin-top: 64px;
}

.analyticsActions button{
  margin: 4px;
}

div[data-handle="clickForSoundButton"]{
  display: none !important;
}

@media print {
  .Analytics .HideOnPrint, footer, .Analytics .pvtRenderers, .Analytics .pvtAxisContainer, .Analytics .pvtVals, .analyticsActions {
    display: none;
  }

  .analyticsDatepicker{
    color: black;
  }

  .Analytics h1{
    color: black;
  }
  body{
    background:none !important;
  }
}

